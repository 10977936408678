<template>
    <div>
        <van-dialog v-model="showDialog" :show-confirm-button="false">
            <div class="dialogContent" v-html="desc"></div>
            <div style="margin: 16px;border-top:1px solid #BBBBBB;" @click="confirm">
                <p class="confirm-text">确认</p>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
    props:{
        desc:{
            type:String,
            default:''
        }
    },

    data() {
        return {
            showDialog:true,
        };
    },
    created() {
    },
    methods: {
        confirm() {
            this.$emit('close')
        },
    },
};
</script>
<style scoped>
.dialogContent {
    max-height: 330px;
    overflow: auto;
    margin: 15px;
    text-align: left;
}
.confirm-text {
    color: #0099FF;
    font-size: 14px;
    text-align: center;
    font-family: PingFangSC-regular;
}
</style>