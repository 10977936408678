<template>
  <div
    class="case_content"
    :class="{ case_child: child }"
    v-if="relation && item.is_show != 0"
  >
    <div class="sub_title" v-if="item.label == '1.药品名称'">联合用药1</div>
    <div class="sub_title" v-if="item.label == '2.药品名称'">联合用药2</div>
    <div class="sub_title" v-if="item.label == '3.药品名称'">联合用药3</div>
    <van-field
      v-if="
        item.type == 'text' &&
        short_name != 'feiai' &&
        short_name != 'qlrb' &&
        item.label != '累计处方天数' &&
        item.label != '累计治疗周期' &&
        item.label != '本次用药周期' &&
        item.label != '处方剂量' &&
        item.label != '观察周期 （第几周期）' &&
        item.label != '周期治疗用量' &&
        item.label != '上一阶段填写周期数量' &&
        item.label != '上一阶段填写处方剂量（支）'
      "
      v-model="item.value"
      :placeholder="item.placeholder ? item.placeholder : '请输入' + item.label"
      rows="1"
      autosize
      :type="item.limit == 'number' ? 'number' : 'textarea'"
      :label="item.label"
      :required="item.require == 1"
      :readonly="item.readonly == 1"
      @blur="onBlur($event, item)"
      @input="onInput($event, item)"
    >
    </van-field>
    <!-- 瑞意人生项目(观察周期、周期治疗用量) start-->
    <van-field
      v-if="
        short_name == 'ryrs' &&
        item.type == 'text' &&
        (item.label == '观察周期 （第几周期）' || item.label == '周期治疗用量')
      "
      v-model="textValue"
      :placeholder="item.placeholder ? item.placeholder : '请输入' + item.label"
      rows="1"
      autosize
      :type="item.limit == 'number' ? 'number' : 'textarea'"
      :label="item.label"
      :required="item.require == 1"
      :readonly="item.readonly == 1"
      @input="onInput($event, item)"
    >
    </van-field>
    <!-- 瑞意人生项目(观察周期) end-->
    <!-- 金字塔（累计处方天数/治疗周期自动计算）start -->
    <van-field
      v-if="item.type == 'text' && item.label == '累计处方天数' && ifShowItem"
      v-model="textValue"
      :placeholder="item.placeholder ? item.placeholder : '请输入' + item.label"
      rows="1"
      autosize
      :type="item.limit == 'number' ? 'number' : 'textarea'"
      :label="item.label"
      :required="item.require == 1"
      :readonly="item.readonly == 1"
      @input="onInput($event, item)"
    >
    </van-field>
    <van-field
      v-if="item.type == 'text' && item.label == '累计治疗周期' && ifShowItem"
      v-model="textValue"
      :placeholder="item.placeholder ? item.placeholder : '请输入' + item.label"
      rows="1"
      autosize
      :type="item.limit == 'number' ? 'number' : 'textarea'"
      :label="item.label"
      :required="item.require == 1"
      :readonly="item.readonly == 1"
      @input="onInput($event, item)"
    >
    </van-field>
    <!-- 金字塔（累计处方天数/治疗周期自动计算）end -->
    <!-- 诺亚方舟（本次用药周期自动计算） start -->
    <van-field
      v-if="item.type == 'text' && item.label == '本次用药周期' && ifShowItem"
      v-model="textValue"
      :placeholder="item.placeholder ? item.placeholder : '请输入' + item.label"
      rows="1"
      autosize
      :type="item.limit == 'number' ? 'number' : 'textarea'"
      :label="item.label"
      :required="item.require == 1"
      :readonly="item.readonly == 1"
      @input="onInput($event, item)"
    >
    </van-field>
    <!-- 诺亚方舟（本次用药周期自动计算） end -->
    <van-field
      v-if="item.type == 'text' && item.label == '处方剂量' && ifShowItem"
      v-model="doseValue"
      :placeholder="item.placeholder ? item.placeholder : '请输入' + item.label"
      rows="1"
      autosize
      :type="item.limit == 'number' ? 'number' : 'textarea'"
      :label="item.label"
      :required="item.require == 1"
      readonly
      @input="onInput($event, item)"
    >
    </van-field>
    <!-- 肺肠安好（周期数量和处方剂量显示） start-->
    <van-field
      v-if="
        item.type == 'text' &&
        short_name == 'fcah' &&
        item.label == '上一阶段填写周期数量'
      "
      v-model="textValue"
      :placeholder="item.placeholder ? item.placeholder : '请输入' + item.label"
      rows="1"
      autosize
      :type="item.limit == 'number' ? 'number' : 'textarea'"
      :label="item.label"
      :required="item.require == 1"
      readonly
      @input="onInput($event, item)"
    >
    </van-field>
    <van-field
      v-if="
        item.type == 'text' &&
        short_name == 'fcah' &&
        item.label == '上一阶段填写处方剂量（支）'
      "
      v-model="textValue"
      :placeholder="item.placeholder ? item.placeholder : '请输入' + item.label"
      rows="1"
      autosize
      :type="item.limit == 'number' ? 'number' : 'textarea'"
      :label="item.label"
      :required="item.require == 1"
      readonly
      @input="onInput($event, item)"
    >
    </van-field>
    <!-- 肺肠安好（周期数量和处方剂量显示） end-->
    <!-- @针对(肺癌科研、精锐计划项目)的周期 start -->
    <van-field
      v-if="
        item.type == 'text' &&
        (short_name == 'feiai' || short_name == 'qlrb') &&
        if_show_item
      "
      v-model="item.value"
      :placeholder="item.placeholder ? item.placeholder : '请输入' + item.label"
      rows="1"
      autosize
      :type="item.limit == 'number' ? 'number' : 'textarea'"
      :label="item.label"
      :required="item.require == 1"
      :readonly="item.readonly == 1"
      @input="onInput($event, item)"
    >
    </van-field>
    <!-- @针对(肺癌科研、精锐计划项目)的周期 end -->
    <van-field
      v-if="item.type == 'textarea'"
      v-model="item.value"
      :placeholder="item.placeholder ? item.placeholder : '请输入' + item.label"
      rows="2"
      autosize
      maxlength="500"
      :type="item.limit == 'number' ? 'number' : 'textarea'"
      :label="item.label"
      :required="item.require == 1"
      :readonly="item.readonly == 1"
    >
      <template v-if="showVideo" #button>
        <van-button
          size="mini"
          icon="volume"
          style="border: none"
          :class="isPlaying ? 'play-btn' : ''"
          @click="playVideo"
        />
      </template>
    </van-field>
    <audio
      v-if="showVideo"
      :autoplay="isPlaying"
      ref="audioMsg"
      :src="vidioUrl"
      id="audio"
      :paused="isPlaying"
      style="display: none"
    ></audio>

    <van-field
      v-if="
        item.type == 'radio' && short_name != 'feiai' && short_name != 'qlrb'
      "
      readonly
      name="picker"
      :value="item.value"
      :placeholder="
        item.placeholder ? item.placeholder : '点击选择' + item.label
      "
      @click="showPick(item)"
      :label="item.label"
      :required="item.require == 1"
      right-icon="arrow-down"
    >
    </van-field>
    <!-- @针对(肺癌科研、精锐计划项目)的周期 start -->
    <van-field
      v-if="
        item.type == 'radio' &&
        (short_name == 'feiai' || short_name == 'qlrb') &&
        if_show_item
      "
      readonly
      name="picker"
      :value="item.value"
      :placeholder="
        item.placeholder ? item.placeholder : '点击选择' + item.label
      "
      @click="showPick(item)"
      :label="item.label"
      :required="item.require == 1"
      right-icon="arrow-down"
    >
    </van-field>
    <!-- @针对(肺癌科研、精锐计划项目)的周期 end -->
    <van-field
      v-if="item.type == 'checkbox'"
      name="checkboxGroup"
      :label="item.label"
      :required="item.require == 1"
    >
      <template #input v-if="item.type == 'checkbox'">
        <van-checkbox-group
          v-if="item.type == 'checkbox'"
          v-model="item.value"
          :max="item.select_count"
        >
          <template v-for="(checkItem, checkIndex) in item.options">
            <van-checkbox
              :name="checkItem"
              shape="square"
              style="margin-bottom: 5px"
              :disabled="item.readonly == 1"
              :key="checkIndex"
            >
              {{ checkItem }}
            </van-checkbox>
          </template>
        </van-checkbox-group>
      </template>
    </van-field>
    <van-field
      v-if="
        item.type == 'date' && short_name != 'feiai' && short_name != 'qlrb'
      "
      readonly
      clickable
      name="datetimePicker"
      :value="item.value"
      :placeholder="
        item.placeholder ? item.placeholder : '点击选择' + item.label
      "
      @click="openTime(item)"
      :label="item.label"
      :required="item.require == 1"
    >
    </van-field>
    <!-- @针对(肺癌科研、精锐计划项目)的周期 start -->
    <van-field
      readonly
      clickable
      name="datetimePicker"
      :value="item.value"
      :placeholder="
        item.placeholder ? item.placeholder : '点击选择' + item.label
      "
      @click="openTime(item)"
      :label="item.label"
      :required="item.require == 1"
      v-if="
        item.type == 'date' &&
        (short_name == 'feiai' || short_name == 'qlrb') &&
        if_show_item
      "
    >
    </van-field>
    <!-- @针对(肺癌科研、精锐计划项目)的周期 end -->
    <template v-if="item.type == 'daterange'">
      <van-field
        readonly
        clickable
        name="calendar"
        :value="item.value.time"
        placeholder="点击选择区间"
        :label="item.label"
        :required="item.require == 1"
        @click="difPick(item)"
      >
      </van-field>
      <van-field
        readonly
        clickable
        name="calendar"
        :value="item.value.day ? item.value.day + '天' : ''"
        placeholder="__天"
      >
        <template #label>
          <span style="margin-right: 5px; color: red" v-if="item.require == 1"
            >*</span
          >
          <span :style="{ color: child ? '#808080' : '' }">{{
            item.daterange_diff ? item.daterange_diff : item.label
          }}</span>
        </template>
      </van-field>
    </template>
    <Upload
      v-if="
        item.type == 'file' && short_name != 'feiai' && short_name != 'qlrb'
      "
      :item="item"
      @getimg="getimg"
      :record_id="record_id"
      :case_id="case_id"
    ></Upload>
    <!-- @针对(肺癌科研、精锐计划项目)的周期  start-->
    <Upload
      v-if="
        item.type == 'file' &&
        (short_name == 'feiai' || short_name == 'qlrb') &&
        if_show_item
      "
      :item="item"
      @getimg="getimg"
      :record_id="record_id"
      :case_id="case_id"
    ></Upload>
    <!-- @针对(肺癌科研、精锐计划项目)的周期  start-->
    <van-popup v-model="timePicker" position="bottom">
      <van-datetime-picker
        cancel-button-text="清除"
        :type="timeType"
        @confirm="timeConfirm"
        @cancel="timeCancel"
        v-model="time"
        :min-date="minDate"
      />
    </van-popup>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        v-if="item.type == 'radio'"
        cancel-button-text="清除"
        show-toolbar
        :columns="columns"
        @confirm="pickConfirm"
        @cancel="pickCancel"
      />
    </van-popup>
    <van-calendar
      v-model="showDif"
      type="range"
      @confirm="difConfirm"
      :minDate="minDate"
    />
    <!-- @肥肠安好处方时间提示弹窗 / 金字塔处方天数提示弹窗-->
    <van-dialog
      v-model="ifShowTips"
      title="温馨提示"
      confirm-button-color="#4B99FF"
      :message="tipsText"
    >
    </van-dialog>
  </div>
</template>

<script>
import Upload from "@/components/upload.vue";
import Vue from "vue";
import { Toast, Uploader, Dialog } from "vant";
import { mapActions, mapState } from "vuex";
import {
  getwordToAudit,
  checkDate,
  isShowReason,
  getFirstPrescriptionDate,
  checkPatient,
  getLastMeasure, //（肥肠安好项目）获取上一阶段处方剂量
} from "@/api/api";
Vue.use(Uploader);

export default {
  components: {
    Upload,
  },
  name: "",
  props: ["steps", "arr", "item", "child", "relation", "father", "case_id"],
  computed: {
    // @剂量
    doseValue: {
      get() {
        if (
          this.item.label == "处方剂量" &&
          (this.scheme_name == '实体肿瘤' || this.scheme_name == '实体瘤（新）' || this.scheme_name == '血液系统疾病' || this.scheme_name == '血液瘤（新）' || this.scheme_name == '护航行动' || this.scheme_name == '护航行动（新）')
        ) {
          this.item.value = "3mg";
        }
        return "3mg";
      },
      set(val) {
        return val;
      },
    },
    // @金字塔-自动计算周期字段
    textValue: {
      get() {
        if (this.item.label == "累计处方天数") {
          let input = this.arr[0].input;
          let index = input.findIndex((ele) => ele.label == "累计处方天数");
          if (index != -1) {
            let days = this.computeDays(
              "IL-11开始用药日期",
              "IL-11结束用药日期",
              input
            ); //computeDays()计算间隔天数
            console.log("this.item.value_content", this.item.value);
            console.log("相减后的时间:days", days);
            if (days <= 0) {
              this.item.value = "";
              Dialog.confirm({
                message: "日期选择有误",
                confirmButtonColor: "#4B99FF",
                title: "温馨提示",
              }).then(() => {});
            } else {
              this.item.readonly = 1;
              if (days) {
                if ((this.scheme_name == '实体肿瘤' || this.scheme_name == '实体瘤（新）')  && days < 5) {
                  this.tipsText = "累计处方天数需大于等于5天，请重新选择日期";
                  this.ifShowTips = true;
                  this.item.value = "";
                } else if (this.scheme_name == '血液系统疾病' || this.scheme_name == '血液瘤（新）') {
                  console.log(this.if_have_disease,'31231313123');
                  this.checkDisease(); // 金字塔项目，判断所选疾病名称中是否为淋巴癌，若有些限制天数为5天，否则为7天
                  if (days < 5 && this.if_have_disease == true) {
                    this.tipsText = "累计处方天数需大于等于5天，请重新选择日期";
                    this.ifShowTips = true;
                    this.item.value = "";
                  } else if (this.if_have_disease == false && days < 7) {
                    this.tipsText = "累计处方天数需大于等于7天，请重新选择日期";
                    this.ifShowTips = true;
                    this.item.value = "";
                  } else {
                    this.item.value = days;
                    return days;
                  }
                } else if (this.scheme_name == '护航行动' || this.scheme_name == '护航行动（新）'){
                  if (this.checkDiseaseType(days,this.scheme_name)) {
                    this.item.value = days;
                    return days
                  }
                } else {
                  this.item.value = days;
                  return days;
                }
              } else {
                return "";
              }
            }
          }
        } else if (this.item.label == "累计治疗周期") {
          this.item.readonly = 1
          if (this.scheme_name == '护航行动' || this.scheme_name == '护航行动（新）'){
            return this.checkTherapyCycle()
          } else {
            this.item.value = 1
            return 1
          }
        } else if (this.item.label == "本次用药周期") {
          let input = this.arr[0].input;
          let index = input.findIndex((ele) => ele.label == "本次用药周期");
          if (index != -1) {
            let days = this.computeDays("开始用药时间", "结束用药时间", input); //computeDays()计算间隔天数
            console.log("this.item.value_content", this.item.value);
            console.log("相减后的时间:days", days);
            this.item.readonly = 1;
            if (days <= 0) {
              this.tipsText = "日期选择有误";
              this.ifShowTips = true;
              this.item.value = "";
            } else {
              if (days) {
                this.item.value = days;
                return days;
              } else {
                this.item.value = "";
                return "";
              }
            }
          }
        } else if (this.item.label == "上一阶段填写周期数量") {
          this.item.value = 2;
          this.item.require = 1;
          return 2;
        } else if (this.item.label == "上一阶段填写处方剂量（支）") {
          this.getDoseData().then((res) => {
            console.log("处方剂量（支）", res);
            this.item.value = res;
            this.item.require = 1;
          });
          return this.measure;
        } else if (this.item.label == "观察周期 （第几周期）") {
          console.log("观察周期：：", this.item);
          this.item.value = this.phase;
          this.item.readonly = 1;
          return this.phase;
        } else if (this.item.label == "周期治疗用量") {
          console.log("周期治疗用量", this.item);
          this.item.value = "0.1g*60片，2盒";
          this.item.readonly = 1;
          return "0.1g*60片，2盒";
        } else {
          return this.item.value;
        }
      },
      set(val) {
        return val;
      },
    },
    list() {
      return this.arr;
    },
    showVideo() {
      if (
        this.short_name == "tqbl" &&
        (this.item.label.indexOf("诊疗信息") != -1 ||
          this.item.label.indexOf("处方单") != -1)
      ) {
        // this.getVideo();
        return true;
      }
      return false;
    },
    ...mapState([
      "userId",
      "basicInformation",
      "scheme_name",
      "form_list",
      "doctor_id",
    ]),
  },
  data() {
    return {
      disabled: false,
      showDif: false,
      timePicker: false,
      showPicker: false,
      columns: [],
      minDate: new Date(1900, 0, 1),
      maxDate: "",
      timeType: "date",
      time: new Date(),
      phase: 1,
      isPlaying: false,
      vidioUrl: "",
      // showVideo: false,
      ifShowItem: false,
      phase: 1, //记录阶段
      short_name: "", //@判断是哪个项目
      if_show_item: true, //@针对肺癌科研/精锐计划项目的周期一和周期二字段不同做显示和必填处理
      // ifUpdate: true, //更新
      date_list: [], //@肥肠安好周期处方日期
      record_id: null,
      tipsText: "", //@肥肠安好处方时间提示弹窗文字
      ifShowTips: false, //@肥肠安好处方时间提示弹窗是否显示
      if_have_disease: false,
      measure: null, //@肺肠安好显示上一阶段处方剂量
    };
  },
  created() {
    console.log("scheme_name:", this.scheme_name);
    this.phase = this.$route.query.phase;
    this.short_name = this.basicInformation.projects[0].short_name;
  },
  mounted() {
    this.record_id = this.$route.query.record_id
      ? this.$route.query.record_id
      : "";
    this.phase = this.$route.query.phase ? this.$route.query.phase : 1;
    if (this.item.type == "radio") {
      if (this.item.options.length == 1) {
        this.item.value = this.item.options[0];
      }
    }
    if (this.item.label == "药品规格") {
      this.item.value = "100mg/瓶";
      this.item.readonly = 1;
    }
    let short_name = this.basicInformation.projects[0].short_name;
    this.short_name = this.basicInformation.projects[0].short_name;
    if (short_name == "jzt" || short_name == "nyfzeq") {
      this.ifShowItem = true;
      console.log("是否显示金字塔/诺亚方舟项目字段：", this.ifShowItem);
    }
    // @针对肺癌科研项目和精锐计划 周期隐藏与必填
    if (
      (short_name == "feiai" && this.scheme_name != "肺癌科研项目3.0") ||
      short_name == "qlrb"
    ) {
      if (
        (this.item.label == "疗效评价时间" ||
          this.item.label == "疗效评价" ||
          this.item.label == "疗效评价标准" ||
          this.item.label == "其他疗效评价标准") &&
        this.item.require == 0
      ) {
        // console.log("隐藏",this.item)
        this.if_show_item = false;
      } else if (this.item.label == "疗效证明性资料") {
        if (this.item.limit == 1) {
          // console.log("显示 疗效证明性资料",this.item)
          this.if_show_item = true;
          this.item.limit = null;
        } else {
          this.if_show_item = false;
        }
      } else if (this.item.label == "至疾病进展时间（PFS）") {
        if (this.item.select_count == 1) {
          this.if_show_item = true;
          this.item.select_count = null;
        } else {
          this.if_show_item = false;
        }
      } else {
        this.if_show_item = true;
      }
    }
  },
  methods: {
    // 肺肠安好项目显示上一阶段处方剂量
    async getDoseData() {
      await getLastMeasure({
        record_id: this.record_id ? this.record_id : this.case_id,
      }).then((res) => {
        console.log("周期数量和处方剂量:", res);
        this.measure = res.measure;
      });
      console.log("this.measure:", this.measure);
      localStorage.setItem("measure", this.measure);
      return this.measure;
    },
    // 安欣保项目医生姓名 医生-患者姓名查重 前端提示不拦截
    async onBlur(event, item) {
      // 安欣保项目医生姓名 医生-患者姓名查重 前端提示不拦截
      if (item.label == "患者姓名") {
        item.value = item.value.replace(/\s*/g, "");
      }
      if (
        item.label == "患者姓名" &&
        (this.short_name == "axb" || this.short_name == "axbplus")
      ) {
        await checkPatient({
          name: item.value,
          phase: this.phase,
          project_doctor_id: this.doctor_id,
          record_id: this.record_id ? this.record_id : this.case_id,
        })
          .then((res) => {
            console.log("res", res);
          })
          .catch((err) => {
            Dialog.alert({
              message: err.response.data.message,
              confirmButtonColor: "#4B99FF",
              title: "温馨提示",
            });
          });
      }
      if (item.label == "患者身份证" && this.short_name == "qlrb") {
        await checkPatient({
          idsn: item.value,
          phase: this.phase,
          project_doctor_id: this.doctor_id,
          record_id: this.record_id ? this.record_id : this.case_id,
        })
          .then((res) => {
            console.log("res", res);
          })
          .catch((err) => {
            Dialog.alert({
              message: err.response.data.message,
              confirmButtonColor: "#4B99FF",
              title: "温馨提示",
            });
          });
      }
      // 白紫科研原发肿瘤最长直径（LD）之和不能为0 
      if(item.label == "原发肿瘤最长直径（LD）之和" && this.short_name === "bzky") {
        if(item.value === '0'  || item.value === '0.0' || item.value === '0.00') {
          Dialog.alert({
            message: '原发肿瘤最长直径（LD）之和不能为0',
            confirmButtonColor: "#4B99FF",
            title: "温馨提示",
          })
          item.value = ''
        }
      }
    },
    // 计算两个日期的天数间隔
    computeDays(start_label, end_label, input) {
      let startDateIndex = input.findIndex(
        (ele) => ele.label.indexOf(start_label) != -1
      );
      let endDateIndex = input.findIndex(
        (ele) => ele.label.indexOf(end_label) != -1
      );
      let startDate = Date.parse(
        new Date(this.arr[0].input[startDateIndex].value.replace(/-/g, "/"))
      );
      let endDate = Date.parse(
        new Date(this.arr[0].input[endDateIndex].value.replace(/-/g, "/"))
      );
      let days = parseInt((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1; //核心：时间戳相减，然后除以天数
      return days;
    },
    // 金字塔项目，判断所选疾病名称中是否为淋巴癌，若有些限制天数为5天，否则为7天
    checkDisease() {
      console.log("项目：", this.short_name);
      if (this.short_name == "jzt") {
        let if_have = this.form_list[0]?.module[0]?.input?.filter((ele) => {
          return ele.label === "疾病名称";
        });
        console.log("if_have", if_have[0]?.value);
        if (if_have[0]?.value == "淋巴癌") {
          this.if_have_disease = true;
        } else {
          this.if_have_disease = false;
        }
      }
    },
    // 金字塔项目 护航行动处方天数 根据疾病类型判断 未选择疾病类型7天
    checkDiseaseType(days, scheme_name) {
      let tipsText = ''
      if(scheme_name == '护航行动') {
        let if_have = this.form_list[0]?.module[0]?.input?.filter(ele => {
          return ele.label === '疾病类型'
        })
        let disease = if_have[0]?.value || []
        console.log('disease', disease)
        if (!disease.length && days < 7) {
          tipsText = '累计处方天数需大于等于7天，请重新选择日期'
        } else if (disease.includes('血液肿瘤') && days < 7) {
          tipsText = `累计处方天数需大于等于${disease.length > 1 ? '5/' : ''}7天，请重新选择日期`
        } else if (days < 5) {
          tipsText = '累计处方天数需大于等于5天，请重新选择日期'
        } else {
          return true;
        }
      } else if(scheme_name == '护航行动（新）') {
        let if_have = this.form_list[1]?.module[0]?.input?.filter(ele => {
          return ele.label === '疾病名称'
        })
        let disease = if_have[0]?.value || ''
        console.log('disease', disease)
        if (!disease && days < 7) {
          tipsText = '累计处方天数需大于等于7天，请重新选择日期'
        } else if ((disease.includes('血液疾病') || disease.includes('血液肿瘤')) && days < 7) {
          tipsText = '累计处方天数需大于等于7天，请重新选择日期'
        } else if (days < 5) {
          tipsText = '累计处方天数需大于等于5天，请重新选择日期'
        } else {
          return true;
        }
      }
      this.tipsText = tipsText
      this.ifShowTips = true
      this.item.value = ''
      return false
    },
    // 金字塔累计治疗周期
    checkTherapyCycle() {
      let input = this.arr[0].input
      let days = this.computeDays('IL-11开始用药日期', 'IL-11结束用药日期', input) //computeDays()计算间隔天数
      let if_have = this.form_list[0]?.module[0]?.input?.filter(ele => {
        return ele.label === '疾病类型'
      })
      let disease = if_have[0]?.value || []
      if (!days) {
        this.item.value = ''
        return ''
      }
      // 满足条件计算累计治疗周期
      if (!disease.length || disease.includes('血液肿瘤')) {
        this.item.value = days >= 7 ? Math.floor(days / 7) : ''
      } else {
        this.item.value = days >= 5 ? Math.floor(days / 5) : ''
      }
      console.log('this.item.value', this.item.value)
      return this.item.value
    },
    onInput(event, item) {
      if ((item.name == "idsn" || item.limit == "idsn") && event.length == 18) {
        this.$emit("Idsn", event, item.name);
      }
    },
    getimg(event, name) {
      // console.log(event,"getImg",name);
      this.$emit("Idsn", event, name);
    },
    afterRead(file) {
      console.log(file, "file");
    },
    difPick(item) {
      if (item.readonly == 1) return false;
      this.showDif = true;
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    difConfirm(time) {
      const [start, end] = time;
      this.item.value = {
        time: `${this.formatDate(start)} - ${this.formatDate(end)}`,
        day: new Date(end - start) / (1000 * 60 * 60 * 24) + 1,
      };
      this.showDif = false;
    },
    openTime(item) {
      console.log("选择时间");
      if (item.readonly == 1) return false;
      this.timePicker = true;
      if (item.date_precision == "month") {
        this.timeType = "year-month";
      } else {
        this.timeType = "date";
      }
    },
    // changeRelation(e) {
    //     console.log(e, 'e')
    //     this.relation = e
    // },
    timeCancel() {
      this.item.value = "";
      console.log("点击取消，清空时间选项");
      this.timePicker = false;
    },
    timeConfirm(value) {
      console.log("时间1：", value);
      console.log("this.case_id:@", this.case_id);
      const d = new Date(value);
      let time = "";
      if (this.timeType == "date") {
        time = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      } else {
        time = d.getFullYear() + "-" + (d.getMonth() + 1);
      }

      console.log("时间2：", d);
      // @针对金字塔项目的用药日期做的判断
      if (
        this.item.label == "IL-11开始用药日期" &&
        this.phase >= 2 &&
        (this.scheme_name == '实体肿瘤' || this.scheme_name == '实体瘤（新）' || this.scheme_name == '血液系统疾病' || this.scheme_name == '血液瘤（新）' || this.scheme_name == '护航行动（新）')
      ) {
        console.log("第二阶段的开始用药日期");
        this.judgeStageDate(time); // 校验阶段日期的填写规则
      } else if (
        this.short_name == "axwn" &&
        (this.item.label == "第二次用药时间" ||
          this.item.label == "第三次用药时间" ||
          this.item.label == "第四次用药时间")
      ) {
        //@针对安心为尼的用药时间做判断
        this.judgeStageDate(time);
      } else if (this.short_name == "feiai" || this.short_name == "qlrb") {
        //@针对肺癌科研和精锐计划
        console.log("this.form_list", this.form_list);
        this.item.value = time;
        let child_arr = [];
        if (this.form_list.length > 3) {
          //首次上传部分
          child_arr = this.form_list[3].module[0].input[0].value;
        } else {
          //后续上传部分
          child_arr = this.form_list[1].module[0].input[0].value;
        }
        // 需要为当日及未来时间的需求废除
        if (
          this.scheme_name == "精锐计划2.0" &&
          this.item.label == "用药时间"
        ) {
          this.judgeStageDate(
            time,
            child_arr,
            "用药时间",
            "请按照时间顺序进行填写"
          );
        }
        if (
          this.short_name == "feiai" &&
          this.item.label == "处方时间" &&
          this.scheme_name == "肺癌科研项目3.0"
        ) {
          this.judgeStageDate(
            time,
            child_arr,
            "处方时间",
            "请按照时间顺序进行填写"
          ); //阶段的时间也要比较
        } else {
          this.judgeDateSort(child_arr, "处方时间", "请按照时间顺序进行填写");
        }
        if (this.item.label == "处方时间") {
          // 判断第每个阶段第一周期所填的处方时间是否在2021年10月1日之后
          this.testDate(child_arr);
        }
        let begin_date = "";
        if (this.item.label == "疗效评价时间") {
          if (this.short_name == "qlrb") {
            begin_date = child_arr[1][0].value; //获取处方时间
          } else {
            if (this.phase == 1) {
              console.log("周期1的处方时间", child_arr[0][0].value);
              begin_date = child_arr[0][0].value; //获取处方时间
            } else {
              begin_date = "";
            }
          }
          this.checkEvaluateDate(begin_date, d); // 检验疗效评价时间是否符合规定区间，传入(处方时间的值,当前所选的时间)
        }
        this.timePicker = false;
      } else if (this.short_name == "fcah") {
        // @针对肥肠安好项目的处方日期做的判断
        console.log("phase:", this.phase);
        this.item.value = time;
        let child_arr = [];
        if (this.form_list.length > 2) {
          //首次上传部分
          child_arr = this.form_list[2].module[0].input[0].value;
        } else {
          //后续上传部分
          if (this.form_list[1].module.length > 1) {
            child_arr = this.form_list[1].module[1].input[0].value;
          } else {
            child_arr = this.form_list[1].module[0].input[0].value;
          }
          // this.judgeStageDate(time, child_arr, '处方日期')
        }
        this.judgeStageDate(
          time,
          child_arr,
          "处方日期",
          "填写处方日期时，请按照时间顺序进行填写"
        );
        // this.judgeDateSort(child_arr,"处方日期","填写处方日期时，请按照时间顺序进行填写")
        this.timePicker = false;
      } else if (
        this.short_name == "ylxs" &&
        this.item.label == "治疗时间" &&
        this.phase >= 2
      ) {
        // @针对怡乐相随项目的治疗时间做的判断
        let child_arr = [];
        child_arr = this.form_list[1].module[0].input[0].value;
        this.judgeStageDate(
          time,
          child_arr,
          "治疗时间",
          "请按照时间顺序进行填写"
        );
      } else if (
        this.short_name == "bxzleq" &&
        (this.item.label == "开始用药日期" ||
          this.item.label == "结束用药日期" ||
          this.item.label == "开始治疗日期" ||
          this.item.label == "结束治疗日期")
      ) {
        // @靶向治疗二期结束日期不能早于开始日期
        this.item.value = time;
        // console.log("开始用药日期",this.item)
        // console.log("form_list",this.form_list)
        this.judgeDateOrder(this.item, this.form_list[2].module);
      } else if (
        this.short_name === "bzky" &&
        this.item.label == "用药时间" &&
        this.phase >= 2
      ) {
        this.judgeStageDate(time);
      } else {
        this.item.value = time;
        this.timePicker = false;
      }
    },
    judgeDateOrder(item, module) {
      //靶向治疗二期开始结束日期校验
      // console.log("module",module[0].input)
      let inputList = module[0].input;
      let date_arr = {};
      if (item.label == "开始用药日期" || item.label == "结束用药日期") {
        for (let i in inputList) {
          if (inputList[i].label == "开始用药日期") {
            // console.log("inputList[i]",inputList[i].label)
            date_arr.start_date = new Date(
              inputList[i].value?.replace(/-/g, "/")
            );
          }
          if (inputList[i].label == "结束用药日期") {
            // console.log("inputList[i]",inputList[i].label)
            date_arr.end_date = new Date(
              inputList[i].value?.replace(/-/g, "/")
            );
          }
        }
      }
      if (
        (item.label == "开始治疗日期" || item.label == "结束治疗日期") &&
        item.asso[0].value == "放疗"
      ) {
        for (let i in inputList) {
          if (
            inputList[i].label == "开始治疗日期" &&
            inputList[i].asso[0].value == "放疗"
          ) {
            // console.log("inputList[i]",inputList[i].label)
            // console.log("inputList[i]",inputList[i].value)
            date_arr.start_date = new Date(
              inputList[i].value?.replace(/-/g, "/")
            );
          }
          if (
            inputList[i].label == "结束治疗日期" &&
            inputList[i].asso[0].value == "放疗"
          ) {
            // console.log("inputList[i]",inputList[i].label)
            // console.log("inputList[i]",inputList[i].value)
            date_arr.end_date = new Date(
              inputList[i].value?.replace(/-/g, "/")
            );
          }
        }
      }
      if (
        (item.label == "开始治疗日期" || item.label == "结束治疗日期") &&
        item.asso[0].value == "有"
      ) {
        for (let i in inputList) {
          if (
            inputList[i].label == "开始治疗日期" &&
            inputList[i].asso[0].value == "有"
          ) {
            // console.log("inputList[i]",inputList[i].label)
            // console.log("inputList[i]",inputList[i].value)
            date_arr.start_date = new Date(
              inputList[i].value?.replace(/-/g, "/")
            );
          }
          if (
            inputList[i].label == "结束治疗日期" &&
            inputList[i].asso[0].value == "有"
          ) {
            // console.log("inputList[i]",inputList[i].label)
            // console.log("inputList[i]",inputList[i].value)
            date_arr.end_date = new Date(
              inputList[i].value?.replace(/-/g, "/")
            );
          }
        }
      }
      // console.log('date_arr',date_arr)
      if (date_arr.start_date > date_arr.end_date) {
        this.tipsText = "结束日期不能早于开始日期";
        this.ifShowTips = true;
        this.item.value = "";
      }
      this.timePicker = false;
      return;
    },
    // 校验肺癌/精锐计划每个阶段第一周期填写的处方时间是否在2021/10/1日之后
    testDate(child_arr) {
      let date_arr = ["2021/10/1"];
      date_arr.push(child_arr[0][0].value.replace(/-/g, "/"));
      console.log("date_arr", date_arr);
      // this.judgeDateSort(date_arr,"处方时间","请选择2021.10.1之后的处方时间")
      let cycle_arr = [];
      for (let i in date_arr) {
        cycle_arr.push(new Date(date_arr[i]));
      }
      for (let i = 0; i < cycle_arr.length; i++) {
        let cycle_date_before = cycle_arr[i];
        for (let j = i + 1; j < cycle_arr.length; j++) {
          let cycle_date_after = cycle_arr[j];
          if (cycle_date_before > cycle_date_after) {
            this.tipsText = "请选择2021.10.1之后的处方时间";
            this.ifShowTips = true;
            this.item.value = "";
          }
        }
      }
    },
    // 校验精锐计划项目的疗效评价时间
    async checkEvaluateDate(begin_date, choose_date) {
      console.log("当前方案名scheme_name:", this.scheme_name);
      // 肺癌科研项目的疗效评价时间校验
      if (this.short_name == "feiai" && this.scheme_name != "肺癌科研项目3.0") {
        if (this.phase > 1) {
          await getFirstPrescriptionDate({
            record_id: this.record_id ? this.record_id : this.case_id,
          })
            .then((res) => {
              console.log("获取第一周期的处方时间", res);
              let endDate = new Date();
              let startDate = new Date(res.replace(/-/g, "/"));
              let range_days =
                parseInt((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1; //核心：时间戳相减，然后除以天数
              let choose_days =
                parseInt((choose_date - startDate) / (1000 * 60 * 60 * 24)) + 1; //核心：时间戳相减，然后除以天数
              let section_start = this.getNewData(res, 90); //区间的起始时间
              let section_end =
                endDate.getFullYear() +
                "." +
                (endDate.getMonth() + 1) +
                "." +
                endDate.getDate();
              if (
                (this.phase > 1 && choose_days < 90) ||
                choose_days > range_days
              ) {
                this.tipsText =
                  "请在时间范围内填写,时间区间为\n" +
                  section_start +
                  "-" +
                  section_end;
                this.ifShowTips = true;
                this.item.value = "";
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        } else {
          if (!begin_date) {
            this.tipsText = "请按顺序填写";
            this.ifShowTips = true;
            this.item.value = "";
            this.timePicker = false;
            return;
          }
          let endDate = new Date();
          let startDate = new Date(begin_date.replace(/-/g, "/"));
          let range_days =
            parseInt((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1; //第一周期处方时间到当天的天数
          let choose_days =
            parseInt((choose_date - startDate) / (1000 * 60 * 60 * 24)) + 1; //所选的疗效评价时间到当天的天数
          console.log("相隔的天数：", range_days);
          console.log("所选日期相隔天数", choose_days);
          console.log("加30天后的日期", this.getNewData(begin_date, 30));
          let section_start = this.getNewData(begin_date, 30); //区间的起始时间
          let section_end =
            endDate.getFullYear() +
            "." +
            (endDate.getMonth() + 1) +
            "." +
            endDate.getDate();
          if (
            (this.phase == 1 && choose_days < 30) ||
            choose_days > range_days
          ) {
            this.tipsText =
              "请在时间范围内填写,时间区间为\n" +
              section_start +
              "-" +
              section_end;
            this.ifShowTips = true;
            this.item.value = "";
          }
        }
      }
      // 精锐计划的疗效评价时间区间为该周期处方时间至当天
      if (this.short_name == "qlrb") {
        if (!begin_date) {
          this.tipsText = "请按顺序填写";
          this.ifShowTips = true;
          this.item.value = "";
          this.timePicker = false;
          return;
        }
        console.log("该周期处方时间为：", begin_date);
        this.checkAuditTime(
          new Date(begin_date.replace(/-/g, "/")),
          choose_date,
          new Date()
        );
        console.log("疗效评价时间 可选时间区间校验");
      }
    },
    // 计算 日期+天数 = 日期
    getNewData(dateTemp, days) {
      var dateTemp = dateTemp.split("-");
      var nDate = new Date(dateTemp[1] + "/" + dateTemp[2] + "/" + dateTemp[0]); //转换为MM/DD/YYYY格式
      var millSeconds = Math.abs(nDate) + (days - 1) * 24 * 60 * 60 * 1000;
      var rDate = new Date(millSeconds);
      var year = rDate.getFullYear();
      var month = rDate.getMonth() + 1;
      // if (month < 10) month = "0" + month;
      var date = rDate.getDate();
      // if (date < 10) date = "0" + date;
      return year + "." + month + "." + date;
    },
    // 判断时间是否在某个时间段内(精锐计划)
    checkAuditTime(start_date, choosed_date, end_date) {
      let cycle_arr = [];
      cycle_arr.push(start_date);
      cycle_arr.push(choosed_date);
      cycle_arr.push(end_date);
      let start_time = "";
      let end_time = "";
      start_time =
        start_date.getFullYear() +
        "." +
        (start_date.getMonth() + 1) +
        "." +
        start_date.getDate();
      end_time =
        end_date.getFullYear() +
        "." +
        (end_date.getMonth() + 1) +
        "." +
        end_date.getDate();
      for (let i = 0; i < cycle_arr.length; i++) {
        let cycle_date_before = cycle_arr[i];
        for (let j = i + 1; j < cycle_arr.length; j++) {
          let cycle_date_after = cycle_arr[j];
          if (cycle_date_before > cycle_date_after) {
            this.tipsText =
              "请在时间范围内填写,时间区间为\n" + start_time + "-" + end_time;
            this.ifShowTips = true;
            this.item.value = "";
          }
        }
      }
    },

    // 校验阶段日期的填写规则(若同时要检验当前页周期时间是否按顺序填写则传入 child_arr)
    async judgeStageDate(time, child_arr, label, tipsText) {
      console.log("child_arr", child_arr);
      await checkDate({
        record_id: this.record_id ? this.record_id : this.case_id,
        date: time,
      })
        .then((res) => {
          console.log("checkDate_res:", res);
          this.item.value = time;
          isShowReason({
            record_id: this.record_id ? this.record_id : this.case_id,
            date: time,
          }).then((res) => {
            this.$emit("isShowReason", res);
          });
          if (child_arr) {
            this.judgeDateSort(child_arr, label, tipsText);
          }
          this.timePicker = false;
        })
        .catch((err) => {
          let jsonstr = JSON.stringify(err.response);
          let json = JSON.parse(jsonstr);
          isShowReason({
            record_id: this.record_id ? this.record_id : this.case_id,
            date: time,
          }).then((res) => {
            this.$emit("isShowReason", 0);
          });
          console.log("err@:", json);
          this.tipsText = json.data.data.message;
          this.ifShowTips = true;
          this.item.value = "";
          console.log("2");
          this.timePicker = false;
        });
    },
    // 判断时间是否按顺序填写(子周期,日期字段，提示语,时间必须为未来时间)
    judgeDateSort(child_arr, label, tip_text, should_be_future) {
      console.log(2222);
      console.log("should_be_future:::", should_be_future);
      let date_arr = [];
      for (let i in child_arr) {
        let cycle_arr = child_arr[i];
        for (let j in cycle_arr) {
          if (cycle_arr[j].label == label) {
            console.log(cycle_arr[j]);
            let date_str = cycle_arr[j]?.value?.replace(/-/g, "/");
            date_arr.push(date_str);
            console.log("日期x：", date_str);
          }
        }
      }
      let cycle_arr = [];
      if (should_be_future) {
        //所填时间需要为今日或未来
        let curDate = new Date();
        let preDate = new Date(curDate.getTime() - 24 * 60 * 60 * 1000); //前一天
        cycle_arr.push(preDate);
      }
      for (let i in date_arr) {
        cycle_arr.push(new Date(date_arr[i]));
      }
      console.log("加入当前时间：", cycle_arr);
      for (let i = 0; i < cycle_arr.length; i++) {
        let cycle_date_before = cycle_arr[i];
        for (let j = i + 1; j < cycle_arr.length; j++) {
          let cycle_date_after = cycle_arr[j];
          if (cycle_date_before >= cycle_date_after) {
            this.tipsText = tip_text;
            this.ifShowTips = true;
            this.item.value = "";
          }
        }
      }
    },
    //页面滚动到顶部
    recover() {
      window.scrollTo(0, 0);
    },
    // 获取最早的处方日期
    getEarliestDate(date_arr) {
      let min_date = null;
      for (let i in date_arr) {
        if (date_arr[i] != "") {
          let min_date = new Date(date_arr[i]); // 把不为空值的日期预设为最小值
        }
      }
      for (let i in date_arr) {
        if (date_arr[i] != "") {
          let item_date = new Date(date_arr[i]);
          if (item_date < min_date) {
            min_date = item_date;
          }
        }
      }
      return min_date;
    },
    showPick(item) {
      if (item.readonly == 1) return false;
      this.columns = item.options;
      this.showPicker = true;
    },
    // 校验例例生辉二期相应字段选择不符合要求则提示“您的病例不符合项目要求”(仅提示不改变所选值)
    checkField(value, label) {
      if (label == "肝功能Child-Pugh分级") {
        this.checkValue(value, "A级", false, "您的病例不符合项目要求"); //所选值，匹配值，单选true多选false
      }
      if (label == "是否一线治疗") {
        this.checkValue(value, "否", true, "您的病例不符合项目要求"); //所选值，匹配值，单选true多选false
      }
      if (label == "患者疗效评价") {
        this.checkValue(value, "PD", true, "您的病例不符合项目要求"); //所选值，匹配值，单选true多选false
      }
      if (label == "体力状况ECOG评分") {
        this.checkValue(value, "其他", true, "您的病例不符合项目要求"); //所选值，匹配值，单选true多选false
      }
      if (label == "是否首次介入联合多纳非尼") {
        this.checkValue(value, "否", true, "您的病例不符合项目要求"); //所选值，匹配值，单选true多选false
      }
      // if(label == '既往是否接受过曲妥珠单抗治疗'){
      //     this.checkValue(value,'否',true,'尊敬的医生：您上传的病例中HER2阳性未接受过曲妥珠单抗治疗，不符合项目入组条件。') //所选值，匹配值，单选true多选false
      // }
    },
    checkValue(value, check_value, is_radio, tips) {
      if (is_radio) {
        if (value == check_value) {
          Dialog.alert({
            message: tips,
            confirmButtonColor: "#4B99FF",
          }).then(() => {
            this.$emit("nextDisabled", true); //点击确认下一步禁止
          });
        } else {
          console.log("是");
          this.$emit("nextDisabled", false); //点击确认开放下一步按钮
        }
      } else {
        if (value != check_value) {
          Dialog.alert({
            message: tips,
            confirmButtonColor: "#4B99FF",
          }).then(() => {
            this.$emit("nextDisabled", true); //点击确认下一步禁止
          });
        } else {
          this.$emit("nextDisabled", false); //点击确认开放下一步按钮
        }
      }
    },
    pickConfirm(value) {
      console.log("this.item", this.item);
      this.item.value = value;
      // 例例生辉二期相应字段选择不符合要求则提示“您的病例不符合项目要求”(仅提示不改变所选值)
      if (
        this.short_name == "llsheq" &&
        (this.item.label == "肝功能Child-Pugh分级" ||
          this.item.label == "是否一线治疗" ||
          this.item.label == "患者疗效评价" ||
          this.item.label == "体力状况ECOG评分" ||
          this.item.label == "是否首次介入联合多纳非尼")
      ) {
        this.checkField(this.item.value, this.item.label);
      }
      // this.time = ''
      this.showPicker = false;
      this.columns = [];
      // @ 针对肺癌科研项目的字段做的特别提示
      if (
        this.short_name == "feiai" &&
        this.item.label == "是否为非小细胞肺癌" &&
        this.item.value == "否"
      ) {
        Dialog.alert({
          title: "温馨提示",
          message: "本项目只针对非小细胞肺癌研究，请确认后及时上传",
          confirmButtonColor: "#4B99FF",
        }).then(() => {});
      }
      if (this.short_name === "bzky" && this.item.label === "疾病名称") {
        this.$emit("bzkyPickerConfirm", this.item);
      }
      // 靶向治疗二期（该限制上线后又要求取消了= =）
      // if(this.short_name == 'bxzleq' && this.item.label == '既往是否接受过曲妥珠单抗治疗') {
      //     this.checkField(this.item.value,this.item.label)
      // }
    },
    pickCancel() {
      this.item.value = "";
      console.log("点击取消，清空字段选项");
      this.showPicker = false;
    },
    getVideo() {
      this.vidioUrl = "";
      if (this.item.value) {
        getwordToAudit({
          params: {
            text: this.item.value,
          },
        })
          .then((res) => {
            console.log(res);
            this.vidioUrl = res;
          })
          .catch((err) => {
            Toast(err.response.data.message);
          });
      }
    },
    playVideo() {
      if (this.isPlaying) {
        this.isPlaying = false;
        this.$refs.audioMsg.pause();
      } else {
        this.getVideo();
        setTimeout(() => {
          this.isPlaying = true;
        }, 200);

        this.$refs.audioMsg.play();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.case_content {
  position: relative;
  &.no-border-bottom {
    &::after {
      border-bottom: none !important;
    }
    ::v-deep .van-cell::after {
      border-bottom: none !important;
    }
  }
  .sub_title {
    font-size: 15px;
    display: flex;
    margin-left: 15px;
    // color: #000;
    color: #646566;
    padding: 15px 0 6px;
  }
  ::v-deep .van-picker__confirm {
    color: #4b99ff;
  }
}

.case_child {
  padding: 0 30px;
}

.case_content::after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 1px solid #ebedf0;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}
.van-button::before {
  background-color: transparent;
}
.play-btn {
  color: #2788ff;
}
</style>