<template>
    <div class="add-configure-page">
        <template v-if="relation&&showItem">
            <div class="father">
                <van-field rows="1" autosize :readonly="true">
                    <template #label>
                        <span style="margin-right:5px;color:red"
                            v-if="item.require==1">*</span><span>{{item.label}}</span>
                    </template>
                    <template #extra>
                        <!-- <span :style="{'font-size':'13px','color':'#849efb'}" @click="addConfigure" v-if="arrowAdd()">添加{{item.config.button_name}}</span> -->
                        <div>
                            <span class="add_configure" @click="addConfigure" v-if="arrowAdd()"><van-icon name="plus" />添加{{item.config.button_name}}</span>
                        </div>
                    </template>
                </van-field>
            </div>
            <template v-for="(itm,idx) in itemChild.value">
                <div v-if="ifShowCollapse && (itemChild.label == '恶心呕吐其他解救性治疗方案' || itemChild.label == '恶心呕吐其他预防方案' || itemChild.label == '5-HT-RA3其他药物' || itemChild.label == 'NK-1-RA其他药物' || itemChild.label == '其他方案')">
                    <van-collapse class="collapse_box" :border="false" v-model="activeCollapse">
                        <van-collapse-item :border="false" :name="`${idx + 1}`" center ref="collapseItem">
                            <template #title>
                                <div class="collapse_child">
                                    <van-field rows="1" autosize :readonly="true">
                                        <template #label>
                                            <!-- <span>{{item.label}}{{idx+1}}</span> -->
                                            <!-- 请求模版id 而且返回数据时（新瑞白） -->
                                            <template v-if="item.configure_template_id&&item.config.secondary_title!=''&&item.config.secondary_title">
                                                <span>{{item.config.secondary_title}}{{idx+1}}</span>
                                            </template>
                                            <template v-else>
                                                <span>{{item.label}}{{idx+1}}</span>
                                            </template>
                                        </template>
                                        <template #extra>
                                            <div class="delete_text">
                                                <span :style="{'font-size':'13px','color':'red'}" @click="delConfigure(idx)"
                                                v-if="arrowDel(idx)">删除{{item.config.button_name}}</span>
                                            </div>
                                        </template>
                                    </van-field>
                                </div>
                            </template>
                            <!-- <template #right-icon>
                                <span :style="{'font-size':'13px','color':'#849efb'}">{{activeCollapse == `${idx+1}` ? '折叠' : '展开'}}</span>
                            </template> -->
                            <template class="child_i" v-for="(childItm,childIdx) in itm">
                                <Content :item="childItm" :child="true" :relation="relationShow(childItm,itm)" :case_id="case_id">
                                </Content>
                            </template>
                        </van-collapse-item>
                    </van-collapse>
                </div>
                <div v-else>
                    <div class="child">
                        <van-field rows="1" autosize :readonly="true">
                            <template #label>
                                <!-- 请求模版id 而且返回数据时（新瑞白） -->
                                <template v-if="item.configure_template_id&&item.config.secondary_title!=''&&item.config.secondary_title">
                                    <span>{{item.config.secondary_title}}{{idx+1}}</span>
                                </template>
                                <template v-else>
                                    <span>{{item.label}}{{idx+1}}</span>
                                </template>
                            </template>
                            <template #extra>
                                <span class="del-btn" @click="delConfigure(idx)" v-if="arrowDel(idx)">删除{{item.config.button_name}}</span>
                            </template>
                        </van-field>
                    </div>
                    <template v-for="(childItm,childIdx) in itm">
                        <Content :item="childItm" :child="true" :relation="relationShow(childItm,itm)" :case_id="case_id" :class="childItm.explain_title ? 'no-border-bottom' : ''">
                        </Content>
                        <!-- <div class="bzky-text-btn" :key="childItm.label">
                            <p>{{ childItm.explain_title }}</p>
                        </div> -->
                        <template v-if="childItm.explain_title && childItm.explain_title!=''">
                            <template v-if="childItm.explain_description && childItm.explain_description!=''">
                                <div class="bzky-text-btn" :key="childItm.label" v-if="childItm.explain_title && childItm.explain_description" @click="handleShowBzkyPopup(childItm)">
                                    <p class="blue">{{ childItm.explain_title }}</p>
                                </div>
                            </template>
                            <template v-else>
                                <div class="bzky-text-btn" :key="childItm.label">
                                    <div class="black">{{ childItm.explain_title }}</div>
                                </div>
                            </template>
                            
                        </template>

                    </template>
                </div>
            </template>
            
        </template>

        <input-notice v-if="showBzkyPopup" @close="showBzkyPopup = false" :desc="currentExplainDescription"></input-notice>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex';
    import {
        Toast
    } from "vant";
    import {
        getConfigureTemplate,
        getConfigureConfig,
        getPreRecordDetail,
    } from '@/api/api'
    import Content from './content.vue'
    import inputNotice from '@/components/inputNotice.vue'
    export default {
        props: ['item', 'relation','case_id', 'preDetail','config'],
        name: '',
        components: {
            Content,
            inputNotice
        },
        data() {
            return {
                ifShowCollapse: false,
                activeCollapse: ['1'],
                collapseLength: null,
                showItem:false,
                showBzkyPopup: false,
                currentExplainDescription: ''


            }
        },
        computed: {
            // @精锐计划，肺癌科研周期显示与隐藏及必填需求
            itemChild() {
                this.short_name = this.basicInformation.projects[0].short_name  
                if(this.short_name == "ztky") {  //@针对止吐科研项目，是否显示折叠和隐藏
                    this.ifShowCollapse = true
                }
                console.log("周期部分0@：",this.item)    
                 // @针对(肺癌科研、精锐计划项目)第二周期的需求字段设置必填 
                if((this.short_name == 'feiai' || this.short_name == 'qlrb')){
                    let item = this.item
                    let cycle_second = this.item.value
                    for(let i in cycle_second[1]){
                        if(cycle_second[1][i].label == "疗效评价时间" ||  cycle_second[1][i].label == "疗效评价" || cycle_second[1][i].label == "疗效评价标准" || cycle_second[1][i].label == "其他疗效评价标准"){
                            cycle_second[1][i].require = 1
                        }
                        if(cycle_second[1][i].label == "疗效证明性资料"){
                            cycle_second[1][i].limit = 1
                        }
                        if(cycle_second[1][i].label == "至疾病进展时间（PFS）"){
                            cycle_second[1][i].select_count = 1
                        }
                    }
                    this.item.value = cycle_second
                    console.log("周期部分2@：",this.item)    
                    return this.item
                }else if(this.short_name == 'fcah') { //肺肠安好项目填写周期预填信息
                    // console.log('this.$route.query.created_at:',this.$route.query.created_at);
                    // const created_at = new Date(this.$route.query.created_at)
                    // const d = new Date(2022, 4, 12, 18, 50, 0, 0);
                    if(!(this.$route.query.is_old_case && this.$route.query.is_old_case == 'true')) { //如果不是老病例
                        let cycle_value = this.item.value
                        console.log('preDetail::', this.preDetail);
                        let stage = this.$route.query.phase ? this.$route.query.phase : 1
                        console.log('stage:',stage)
                        if(stage == 1) {
                            for(let i in cycle_value[0]) {
                                if(cycle_value[0][i].label == '药品名称') {
                                    cycle_value[0][i].value =  this.preDetail['药品名称']
                                    cycle_value[0][i].readonly = 1
                                }
                                if(cycle_value[0][i].label == '处方日期') {
                                    cycle_value[0][i].value =  this.preDetail['处方日期']
                                    cycle_value[0][i].readonly = 1
                                }
                                if(cycle_value[0][i].label == '处方剂量（支）') {
                                    cycle_value[0][i].value = this.preDetail['处方剂量']
                                    cycle_value[0][i].readonly = 1
                                }
                                if(cycle_value[0][i].label == '用药证明') {
                                    cycle_value[0][i].value = this.preDetail['用药证明'] ? this.preDetail['用药证明'] : cycle_value[0][i].value
                                    cycle_value[0][i].readonly = 1
                                }
                            }
                        }
                        this.item.value = cycle_value
                        return this.item
                    }else {
                        return this.item
                    }
                }else{
                    if(this.short_name == "ztky") {  //@针对止吐科研项目，点添加时默认展开添加的那一项
                        this.collapseLength = this.item.value.length
                        console.log("长度：",this.collapseLength)
                        let length = this.collapseLength + ''
                        this.activeCollapse = [length] 
                    }
                    
                    return this.item
                }
            },
            arrowDel() {
                return function (index) {
                    if (index < this.item.select_min_count) {
                        return false
                    } else {
                        return true
                    }
                }
            },
            arrowAdd() {
                return function () {
                    if (this.item.value.length >= this.item.select_count) {
                        return false
                    } else {
                        return true
                    }
                }
            },
            ...mapState(["basicInformation","scheme_name"])
        },
        watch: {
            
            relation(newValue, oldValue) {
                let item = this.item
                if (item.child && item.child.length > 0) {
                    item.value = []
                    item.value = this.item.child
                } else {
                    if (item.value && item.value.length > 0) {

                    } else {
                        item.value = []
                    }
                }
                this.item = item
                console.log(this.item.value, this.relation, newValue, oldValue)
            }
        },
        async created() {
            this.getConfigure_config(this.item.configure_template_id).then(res =>{
                this.item.config = res
                this.showItem = true
            })
            
            console.log(this.item,"this.item")

        },
        mounted() {
            // console.log("configure@mouted_item:",this.item)
            // console.log("configure@mouted_case_id:",this.case_id)
            let item = this.item
            if (item.child && item.child.length > 0) {
                item.value = []
                item.value = this.item.child
            } else {
                if (item.value && item.value.length > 0) {

                } else {
                    this.addChild()
                }
            }
            this.item = item
        },
        methods: {
            handleShowBzkyPopup(item) {
                this.currentExplainDescription = item.explain_description
                this.showBzkyPopup = true
            },
            getConfigure_config(id) {
                 let template_structure = getConfigureConfig({
                    params: {
                        configure_template_id: id
                    }
                })
                return template_structure
            },
            async fillPreDetail(cycle_value) {   //肺肠安好项目周期1填写预填信息
                await getPreRecordDetail({
                    record_id: this.case_id
                }).then(res => {
                    for(let i in cycle_value[0]) {
                        if(cycle_value[0][i].label == '药品名称') {
                            cycle_value[0][i].value = res['药品名称']
                            cycle_value[0][i].readonly = 1
                        }
                        if(cycle_value[0][i].label == '处方日期') {
                            cycle_value[0][i].value = res['处方日期']
                            cycle_value[0][i].readonly = 1
                        }
                        if(cycle_value[0][i].label == '处方剂量（支）') {
                            cycle_value[0][i].value = res['处方剂量']
                            cycle_value[0][i].readonly = 1
                        }
                        if(cycle_value[0][i].label == '用药证明') {
                            cycle_value[0][i].value = res['用药证明']
                            cycle_value[0][i].readonly = 1
                        }
                    }
                })
                return cycle_value
            },
            relationShow(itm, value) {
                let list = value
                if (itm.asso && itm.asso.length > 0) {
                    let isshow = false
                    for (let i in itm.asso) {
                        for (let j in list) {
                            if (list[j].name.indexOf(itm.asso[i].name) != '-1') {
                                if (itm.asso[i].type == 'daterange') {
                                    if (itm.asso[i].value == list[j].value.day) {
                                        isshow = true
                                    } else {
                                        itm.isShow = false
                                        isshow = false
                                        return false
                                    }
                                } else if (itm.asso[i].type == 'checkbox') {
                                    if (list[j].value.indexOf(itm.asso[i].value) != -1) {
                                        isshow = true
                                    } else {
                                        console.log("itm",itm)
                                        // 切换多选选中时清除未选的关联选项的值
                                        if(itm.type == 'radio') {
                                            itm.value = ""
                                        }
                                        if(itm.type == 'text') {
                                            itm.value = ""
                                        }
                                        if(itm.type == 'file') {
                                            // console.log("文件",itm)
                                            itm.value = []
                                        }
                                        if(itm.type == 'checkbox') {
                                            // console.log("多选",itm)
                                            while(itm.value.length>0) {
                                                itm.value.pop()
                                            }
                                        }
                                        if(itm.type == 'date') {
                                            itm.value = ""
                                        }
                                        itm.isShow = false
                                        isshow = false
                                        return false
                                    }
                                } else {
                                    if (itm.asso[i].value == list[j].value) {
                                        isshow = true
                                    } else {
                                        // 切换选项时清除未选的关联选项的值
                                        if(itm.type == 'radio') {
                                            itm.value = ""
                                        }
                                        if(itm.type == 'text') {
                                            itm.value = ""
                                        }
                                        if(itm.type == 'file') {
                                            // console.log("文件",itm)
                                            itm.value = []
                                        }
                                        if(itm.type == 'checkbox') {
                                            // console.log("多选",itm)
                                            while(itm.value.length>0) {
                                                itm.value.pop()
                                            }
                                        }
                                        if(itm.type == 'date') {
                                            itm.value = ""
                                        }
                                        itm.isShow = false
                                        isshow = false
                                        return false
                                    }
                                }

                            }
                        }
                    }
                    if (isshow) {
                        itm.isShow = true
                        return true
                    } else {
                        itm.isShow = false
                        return false
                    }
                } else {
                    if(typeof itm == 'number') {
                        console.log('错误原因：itm:',typeof itm, itm);
                    }
                    if(typeof itm == 'string') {
                        console.log('错误原因:',typeof itm, itm);
                    }
                    if(typeof itm != 'number' && typeof itm != 'string'){
                        itm.isShow = true
                        return true
                    }
                    
                }

            },
            getColor(itm) {
                if (itm) {
                    if (itm.value && itm.value.length && itm.value.length > 0) {
                        return 'red'
                    } else {
                        return '#849efb'
                    }
                }
            },
            getButton(itm) {
                if (itm) {
                    if (itm.value && itm.value.length && itm.value.length > 0) {
                        return '删除'
                    } else {
                        return '添加'
                    }
                }
            },
            async addChild() {
                this.item.value = []
                for (let i = 0; i < this.item.select_min_count; i++) {
                    try {
                        let res = await getConfigureTemplate({
                            params: {
                                configure_template_id: this.item.configure_template_id
                            }
                        })
                        let data = []
                        for (let i in res) {
                            data.push(res[i])
                        }
                        this.item.value.push(data)
                    } catch (err) {
                        Toast(err.response.data.message)
                    }
                }

            },
            async addConfigure() {
                let item = this.item
                if (this.$route.query.scheme_id == 1) {
                  if (item.value.length > 0) {
                    let fcah_measure_total = 0;
                    item.value.forEach((valueItem1)=>{
                      if (valueItem1.length > 0) {
                        valueItem1.forEach((valueItem2)=>{
                          if (valueItem2.label == '处方剂量（支）') {
                            fcah_measure_total += parseInt(valueItem2.value?valueItem2.value:0);
                          }
                        });
                      }
                    });
                    console.log('支数：',localStorage.getItem("measure"));
                    console.log('fcah_measure_total:', fcah_measure_total);
                    if(localStorage.getItem("measure")) {
                        let measure_total_result = fcah_measure_total + parseInt(localStorage.getItem("measure"))
                        console.log('measure_total_result',measure_total_result);
                        if (measure_total_result >= 16 && this.scheme_name != "肺癌（标准）2.0") {
                            console.log("scheme_name",this.scheme_name)
                            Toast('处方剂量总数已达到16支,请提交病例');
                            return false;
                        }
                    }else {
                        if (fcah_measure_total >= 16 && this.scheme_name != "肺癌（标准）") {
                        console.log("scheme_name",this.scheme_name)
                        Toast('处方剂量总数已达到16支,请提交病例');
                        return false;
                        }
                    }
                  }
                }
                if (item.value.length >= item.select_count) return false
                let loading = Toast.loading({duration:0,forbidClick:true,message:'加载中...'});
                try {
                    let res = await getConfigureTemplate({
                        params: {
                            configure_template_id: this.item.configure_template_id
                        }
                    })
                    let data = []
                    for (let i in res) {
                        data.push(res[i])
                    }
                    item.value.push(data)
                    this.item = item
                    loading.clear();
                } catch (err) {
                    loading.clear();
                    Toast(err.response.data.message)
                }
            },
            delConfigure(idx) {
                this.item.value.splice(idx, 1)
            },
        },


    }
</script>

<style lang="scss">
.add-configure-page {
    .add_configure {
        font-size: 13px; color: white; background-color: #67C3C3; padding: 5px 10px;
    }
    .collapse_box { 
        padding: 0;
        .delete_text{
            display: flex;
            align-items: center;
        }
        .collapse_child{
            .van-cell{
                padding: 0 36px 0 20px;
            }
        }
    }

    .bzky-text-btn {
        padding: 0 16px;
        font-size: 14px;;
        color:#4B99FF;
        font-family: PingFangSC-regular;
        p {
            // margin-top: 0;
            // padding-bottom: 10px;
            // border-bottom: 1px solid #ebedf0;
        }
        .black{
            color:#333;
            border-bottom: 1px solid #ebedf0;
            padding-bottom: 10px;
        }
    }
    .del-btn{
        font-size: 12px;
        height: 18px;
        color: red;
        border: 1px solid #FF3535;
        line-height: 18px;
        padding: 0 3px;
        border-radius: 3px;
        margin-top: 3px;
    }
}
    
    
    .child {
        position: relative;
        padding: 0 35px;
    }
    .child::after {
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        right: 16px;
        bottom: 0;
        left: 16px;
        border-bottom: 1px solid #ebedf0;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
    }
    .case_content{
        &.no-border-bottom{
            &::after {
                border-bottom: none !important;
            }
            ::v-deep .van-cell::after {
                border-bottom: none !important;
            }
        }
    }

    .father {
        position: relative;
        .van-field{
            display: flex;
            align-items: center;
        }
        .btn-container{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 25px;
            font-size: 13px;
            color: white;
            background-color: #2EC6C4;
            padding: 0px 5px;
            border-radius: 4px;
            font-weight: bold;
            .icon{
                width: 10px;
                height: 10px;
            }
            .addbtn{
                margin-left: 1px;
                font-style: normal;
            }
            
        }
    }


    .father::after {
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        right: 16px;
        bottom: 0;
        left: 16px;
        border-bottom: 1px solid #ebedf0;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
    }

    
</style>